import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../Layouts/Science/ScienceLayout";
import {NavLink} from "react-router-dom";

const ScienceTable = () => {
    const {lang} = useSelector(state => state.lang);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        fetch(`https://bricsyoung.com/api/university`)
            .then((res) => res.json())
            .then((data) => {
                setData(Array.isArray(data) ? data : [data]);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Fetch error:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData()
    }, []);
    const columns = [
        {
            title: "Университет",
            dataIndex: "header",
            key: "header",
            render: (text, record) => <NavLink to={"" + record.id}>{text}</NavLink>,
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Тип финансирования",
            dataIndex: "finance",
            key: "finance",
            render: (text) => {
                switch (text) {
                    case "стипндия":
                        return "Стипендия";
                    case "гранты":
                        return "Гранты";
                    case "зарплата":
                        return "Зарплата";
                    default:
                        return "Не указано";
                }
            }
        }
    ];

    const enColumns = [
        {
            title: "University",
            dataIndex: "headeren",
            key: "headeren",
        },
        {
            title: "Description",
            dataIndex: "descriptionen",
            key: "descriptionen",
        },
        {
            title: "Type of financing",
            dataIndex: "finance",
            key: "finance",
            render: (text) => {
                switch (text) {
                    case "стипндия":
                        return "Scholarship";
                    case "гранты":
                        return "Grants";
                    case "зарплата":
                        return "Salary";
                    default:
                        return "-";
                }
            }
        }
    ]

    return (
        <ScienceLayout title={lang === 'ru' ? 'Наука' : 'Science'} lang={lang}>
            <Table loading={loading} columns={lang === 'ru' ? columns : enColumns} dataSource={data} pagination={false}/>
        </ScienceLayout>
    )
};

export default ScienceTable;
