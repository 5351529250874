import {Card, Col, ConfigProvider, Row, Tooltip} from "antd";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {MainThemes} from "../../../themes/MainThemes";
import s from "../../Main/NewsBlock/News.module.css";
import React from "react";

export const GrantsBlock = ({
                                response,
                                load = true,
                                url = "/contest/",
                            }) => {
    return (
        <Row gutter={[32, 32]}>
            {load
                ? response.map((el) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={el.id}>
                        <GrantsCard {...el} url={`${url + el.id}`}/>
                    </Col>
                ))
                : Array.from({length: 8}).map((_, index) => (
                    <Col span={6} key={index}>
                        <Card loading={true}/>
                    </Col>
                ))}
        </Row>
    );
};

export const GrantsCard = ({
                             name,
                               nameen,
                             img,
                             url,
                         }) => {
    const { lang } = useSelector((state) => state.lang);

    return (
        <Tooltip title={lang === "ru" ? name : nameen}>
            <NavLink to={url}>
                <ConfigProvider theme={MainThemes.news}>
                    <Card
                        className={s.card}
                        cover={
                            <img
                                src={img}
                                style={{
                                    width: "calc(100% - 2px)",
                                    height: "auto",
                                    border: "1px solid #4d4d4d",
                                    borderBottom: "none",
                                    aspectRatio: "16/9",
                                    objectFit: "cover",
                                }}
                                alt={lang === "ru" ? name : nameen}
                            />
                        }
                    >
                        <div className={s.text}>
                            <h3 className={s.textNews}>
                                {lang === "ru" ? name : nameen}
                            </h3>
                        </div>
                    </Card>
                </ConfigProvider>
            </NavLink>
        </Tooltip>
    );
};
