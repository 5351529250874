export const MainThemes = {
    news: {
        components: {
            Card: {
                paddingLG: 19,
                borderRadiusLG: 20
            }
        }
    },
    events: {
        components: {
            Card: {
                paddingLG: 0
            }
        }
    },
    slider: {
        components: {
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none"
            }
        }
    },
    newSlider: {
        components: {
            Button: {
                defaultColor: "#4D4D4D",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                defaultBorderColor: "#4D4D4D",
                borderRadius: 4,
                paddingInline: 20,
                paddingBlock: 29,
                fontSize: 20,
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)"
            }
        }
    },
    youtube: {
        components: {
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultColor: "white",
                borderRadius: 4,
                fontSize: 20,
                defaultBorderColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                paddingInline: 20,
                paddingBlock: 29,
            }
        }
    },
    gallery: {
        components: {
            Button: {
                defaultBg: "white",
                defaultColor: "#4d4d4d",
                borderRadius: 4,
                fontSize: 20,
                defaultBorderColor: "#4d4d4d",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                paddingInline: 20,
                paddingBlock: 29,
            }
        }
    },
    forum: {
        token: {
            colorText: '#4D4D4D',
            colorPrimary: '#4D4D4D',
        },
        components: {
            Collapse: {
                contentBg: "white",
                headerBg: "white",
                fontSize: 20,
                borderRadiusLG: 0,
                borderRadius: 0,
                paddingSM: 30,
            },

            Menu: {
                colorText: '#4D4D4D',
                colorPrimary: '#4D4D4D',
                horizontalItemSelectedColor: "#385FEB",
                horizontalItemHoverColor: "#385FEB",
                itemSelectedColor: "#385FEB",
                subMenuTitleColor: '#385FEB',
                inlineIndent: 20,
                padding: 20,
                fontSize: 20
            },
            Button: {
                defaultBg: 'rgba(56, 95, 235, 1)',
                defaultHoverBg: 'white',
                defaultActiveBg: 'white',
                defaultColor: "white",
                defaultActiveColor: "rgba(56, 95, 235, 1)",
                defaultHoverColor: "rgba(56, 95, 235, 1)",
                colorBorder: "none",
                defaultHoverBorderColor: "rgba(56, 95, 235, 1)",
                defaultActiveBorderColor: "none",
                paddingBlock: 22,
                fontSize: 20,
                paddingInline: 20
            }

        }
    },
    science: {
        token: {
            colorText: '#4D4D4D',
            colorPrimary: '#4D4D4D',
        },
        components: {
            Collapse: {
                contentBg: "white",
                headerBg: "white",
                fontSize: 20,
                borderRadiusLG: 0,
                borderRadius: 0,
                paddingSM: 30,
            },

            Menu: {
                colorText: '#4D4D4D',
                colorPrimary: '#4D4D4D',
                horizontalItemSelectedColor: "#385FEB",
                horizontalItemHoverColor: "#385FEB",
                itemSelectedColor: "#4D4D4D",
                subMenuTitleColor: '#385FEB',
                inlineIndent: 20,
                padding: 20,
                fontSize: 20,
            }

        }
    }
}