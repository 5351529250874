import s from "../../News/SingleNewsPage/NewsLayout.module.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const UniversitySinglePage = () => {
    const { id } = useParams();
    const [loading2, setLoading2] = useState(true);
    const [news, setNews] = useState({
        name: "loading...",
        nameen: "loading...",
        img: "",
        body: "loading...",
        bodyen: "loading...",
        headeren: "loading...",
    });
    const { lang } = useSelector((state) => state.lang);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `https://bricsyoung.com/api/grants?id=${id}`
                );
                const data = await response.json();
                setNews(data[0]);
            } catch (e) {
            } finally {
                setLoading2(false);
            }
        };
        fetchData();
    }, [id]);

    if  (loading2) {
        return <div>Loading...</div>;
    }

    return (
        <div className={s.root}>
            <div className={s.layout}>
                <div className={s.main}>
                    <article className={s.articleDetailBlock}>
                        <div className={s.title}>


                            <h1>{lang === "ru" ? news.header : news.headeren}</h1>
                        </div>
                        <img src={"https://bricsyoung.com" + news.img} alt="" />

                        <div className={s.text}>
                            <b>{lang === "ru" ? news?.description : (news?.descriptionen)}</b>
                        </div>
                        <div className={s.text}>
                            {lang === "ru" ? parse(news?.body) : parse(news?.bodyen)}
                        </div>
                    </article>
                </div>
            </div>
        </div>
    );
};

export default UniversitySinglePage;
