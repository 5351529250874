import s from "./Main.module.css"
import NewsCarousel from "../../Components/Main/NewsCarousel";
import EventsBlock from "../../Components/Main/EventsBlock";
import NewsBlock from "../../Components/Main/NewsBlock";
import FlagsBlock from "../../Components/Main/FlagsBlock";
import {useEffect} from "react";
import PartnersBlock from "../../Components/About/PartnersBlock";
import {useSelector} from "react-redux";
import {MiniHistoryBlock} from "../../Components/About/HistoryBlock";

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const {lang} = useSelector(state => state.lang);
    return (
        <div className={s.main}>
            <NewsCarousel/>
            {/* <YouTubeLiveStream/> */}
            <EventsBlock/>
            <NewsBlock/>
            <FlagsBlock/>
            {/* <VideoPlayer videoId={1} /> */}
            <MiniHistoryBlock lang={lang}/>
            <PartnersBlock lang={lang}/>
        </div>
    )
}


export default Main
