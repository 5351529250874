import s from "./NewsLayout.module.css";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { useSelector } from "react-redux";
import { formatDate, NewsDisplay } from "../../../Components/Main/NewsBlock";
import { ArrowRightOutlined } from "@ant-design/icons";

const SingleNewsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [news, setNews] = useState({
    date: "2000-01-01",
    header: "loading...",
    img: "",
    body: "loading...",
    bodyen: "loading...",
    headeren: "loading...",
  });
  const [newsArray, setNewsArray] = useState(null);
  const [response, setResponse] = useState(null);
  const { lang } = useSelector((state) => state.lang);
  const load = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    const loadInfo = async () => {
      try {
        const res = await axios.get("https://bricsyoung.com/api/news");
        setNewsArray(res.data);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    loadInfo();
  }, []);

  useEffect(() => {
    if (newsArray) {
      const data = Object.values(newsArray).map((item) => ({
        body: item.body || "",
        bodyen: item.bodyen || "",
        date: item.date || "2024-07-01",
        description: item.description || "",
        descriptionen: item.descriptionen || "",
        header: item.header || "",
        headeren: item.headeren || "",
        id: item.id || "",
        img: "https://bricsyoung.com" + item.img || "",
      }));
      setResponse(data);
    }
  }, [newsArray]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://bricsyoung.com/api/news?id=${id}`
        );
        const data = await response.json();
        setNews(data[0]);
      } catch (e) {
      } finally {
        setLoading2(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading && loading2) {
    return <div>Loading...</div>;
  }
  if (!response) {
    return <div>No response...</div>;
  }

  return (
    <div className={s.root}>
      <div className={s.layout}>
        <div className={s.main}>
          <article className={s.articleDetailBlock}>
            <div className={s.title}>
              <div className={s.date}>{formatDate(news.date, lang)}</div>

              <h1>{lang === "ru" ? news.header : news.headeren}</h1>
            </div>
            <img src={"https://bricsyoung.com" + news.img} alt="" />

            <div className={s.text}>
              <b>{lang === "ru" ? news?.description : (news?.descriptionen)}</b>
            </div>
            <div className={s.text}>
              {lang === "ru" ? parse(news?.body) : parse(news?.bodyen)}
            </div>
          </article>

          <BlockTitleWithUrl
            size="27px"
            ruTitle="Также рекомендуем"
            enTitle="Also Read"
            url="/news"
          />
          <NewsDisplay response={response.slice(0, 4)} load={load} />
        </div>
      </div>
    </div>
  );
};

export const BlockTitleWithUrl = ({ url, ruTitle, enTitle, size }) => {
  const { lang } = useSelector((state) => state.lang);
  return (
    <div className={s.bottom}>
      <div className={s.also}>
        <h2 style={{ fontSize: size }}>{lang === "ru" ? ruTitle : enTitle}</h2>
        <NavLink to={url}>
          <span>{lang === "ru" ? "Смотреть все" : "See All"}</span>
          <span className={s.arrow}>
            <ArrowRightOutlined />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default SingleNewsPage;
