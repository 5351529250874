import {ConfigProvider, Menu} from "antd";
import {MainThemes} from "../../themes/MainThemes";
import styles from "./ScienceLayout.module.css"
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";

export const ScienceLayout = ({children, title}) =>{
    const location = useLocation();
    const {lang} = useSelector(state => state.lang);
    const getSelectedKey = (pathname) => {
        return pathname;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const items = [
        {
            key: "/science",
            label: <NavLink to="/science">{lang === 'ru' ? "Главная" : "Main Page"}</NavLink>,
        },
        // {
        //     key: "/science/map",
        //     label: <NavLink to="/science/map">{lang === 'ru' ? "Интерактивная карта с университетами и программами обмена" : "Interactive map with universities and exchange programs"}</NavLink>,
        // },
        {
            key: "/science/competitions/all",
            label: <NavLink to="/science/competitions/all">{lang === 'ru' ? "Конкурсы и гранты" : "Competitions and grants"}</NavLink>,
            children: [
                {
                    key: "/science/competitions/китай",
                    label: <NavLink to="/science/competitions/китай">{lang === 'ru' ? "Конкурсы и гранты Китая" : "Competitions and grants in China"}</NavLink>,
                },
                {
                    key: "/science/competitions/бразилия",
                    label: <NavLink to="/science/competitions/бразилия">{lang === 'ru' ? "Конкурсы и гранты Бразилии" : "Competitions and grants in Brazil"}</NavLink>,
                },
                {
                    key: "/science/competitions/саудовская_аравия",
                    label: <NavLink to="/science/competitions/саудовская_аравия">{lang === 'ru' ? "Конкурсы и гранты Саудовской Аравии" : "Competitions and grants in Saudi Arabia"}</NavLink>,
                },
                {
                    key: "/science/competitions/аргентина",
                    label: <NavLink to="/science/competitions/аргентина">{lang === 'ru' ? "Конкурсы и гранты Аргентины" : "Competitions and grants in Argentina"}</NavLink>,
                },
            ],
        },
        // {
        //     key: "/science/success_stories",
        //     label: <NavLink to="/science/success_stories">{lang === 'ru' ? "Истории успеха" : "Success Stories"}</NavLink>,
        // }

    ]

    return (
        <ConfigProvider theme={MainThemes.science}>
            <div className={styles.science}>
                <h1>
                    {title}
                </h1>
                <div className={styles.info}>
                    <div className={styles.left}>
                        {children}
                    </div>
                    <div className={styles.right}>
                        <div className={styles.menuWrapper}>
                            <Menu mode="inline" style={{ justifyContent: "left", borderRight: "none" }}
                                  selectedKeys={[getSelectedKey(location.pathname)]} items={items} />
                        </div>
                        <style jsx>{`
                            .ant-menu-item-selected {
                                background-color: white !important;
                            }
                            .ant-menu-sub  {
                                background-color: white !important;
                            }
                            .ant-menu-submenu-title a {
                                color: #4d4d4d;
                            }
                        `}</style>
                    </div>
                </div>
            </div>
        </ConfigProvider>
    );
}