import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {ScienceLayout} from "../../../../Layouts/Science/ScienceLayout";
import axios from "axios";
import {GrantsBlock} from "../../../../Components/Science/GrantsBlock";
import {useParams} from "react-router-dom";

const ScienceCompetitions = () => {
    const {lang} = useSelector(state => state.lang);
    const [news, setNews] = useState([]);
    const [load, setLoad] = useState(false);
    const {country} = useParams();

    const loadInfo = async () => {
        try {
            const res = await axios.get("https://bricsyoung.com/api/grants");
            setNews(res.data);
            console.log(res.data);
            setLoad(true);
        } catch (error) {
        }
    };

    useEffect(() => {
        loadInfo();
    }, [country]);

    const response = Object.values(news)
        .map((item) => ({
            body: item.body,
            bodyen: item.bodyen,
            country: item.country,
            nameen: item.nameen,
            name: item.name,
            id: item.id,
            img: "https://bricsyoung.com" + item.img,
        }))
        .filter((item) => {
            if (country === 'all') {
                return true; // Показываем все элементы, если country равно "all"
            } else {
                return item.country.includes(country); // Иначе фильтруем по country
            }
        });

    return (
        <ScienceLayout title={lang === 'ru' ? 'Конкурсы и гранты' : 'Competitions and grants'} lang={lang}>
            <GrantsBlock load={load} response={response}/>
        </ScienceLayout>
    )
};

export default ScienceCompetitions;