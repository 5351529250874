import s from './Partners.module.css'
import p1 from "../../../assets/Partners/SN.jpg"
import p2 from "../../../assets/Partners/svg/sabya.svg"
import p3 from "../../../assets/Partners/svg/ТВ БРИКС.svg"
import p4 from "../../../assets/Partners/svg/assamb_2.png"
import p5 from "../../../assets/Partners/svg/agency.svg"
import p6 from "../../../assets/Partners/svg/iyesf.svg"
import p7 from "../../../assets/Partners/svg/rudn.svg"
import p8 from "../../../assets/Partners/svg/aho.svg"
import p9 from "../../../assets/Partners/svg/ra.svg"
import p10 from "../../../assets/Partners/svg/bayeux.svg"
import p11 from "../../../assets/Partners/svg/youth.svg"
import p12 from "../../../assets/Partners/svg/smart.png"
import p13 from "../../../assets/Partners/svg/hpgu.svg"
import p14 from "../../../assets/Partners/svg/red.svg"
import p15 from "../../../assets/Partners/svg/skur.svg"
import p16 from "../../../assets/Partners/svg/inostr.png"
import {Tooltip} from "antd";
import {useSelector} from "react-redux";

const PartnersBlock = () => {
    const {lang} = useSelector(state => state.lang);
    return (
        <div className={s.partners}>
            <div className={s.info}>
                {partnersArray
                    .map((el) =>
                        <Tooltip className={s.block} key={el.key} title={lang === 'ru' ? el.title : el.enTitle}>
                            <a target='_blank' href={el.url}>
                                <img src={el.img} alt=""/>
                            </a>

                        </Tooltip>
                    )
                }
            </div>
        </div>
    )
}
export default PartnersBlock

const partnersArray = [
    {
        key: "p1",
        img: p1,
        title: "ООО «Cпортивное новаторство»",
        enTitle: "Sport Novators",
        url: "https://sportnovators.ru/"
    },
    {
        key: "p2",
        img: p2,
        title: "Южно-Африканская Молодежная Ассоциация БРИКС (SABYA, ЮАР)",
        enTitle: "SABYA (South African BRICS Youth Association)",
        url: "https://sabya.co.za/"
    },
    {
        key: "p3",
        img: p3,
        title: "ТВ БРИКС",
        enTitle: "TV BRICS",
        url: "https://tvbrics.com/en/"
    },
    {
        key: "p4",
        img: p4,
        title: "Ассамблея народов Евразии и Африки",
        enTitle: "Eurasian And African Peoples’ Assembly",
        url: "http://eurasia-assembly.org/"
    },
    {
        key: "p5",
        img: p5,
        title: "Агентство культурной и научной дипломатии (г. Екатеринбург)",
        enTitle: "Agency of Cultural and Science Diplomacy",
        url: "https://cdaekb.ru/"
    },
    {
        key: "p6",
        img: p6,
        title: "Международный фонд повышения образования и навыков среди молодежи (IYESF, Индия)",
        enTitle: "IYESF (International Youth Edu-Skills Foundation)",
        url: "https://www.iyesf.com/"
    },
    {
        key: "p7",
        img: p7,
        title: "Российский университет дружбы народов имени Патриса Лумумбы",
        enTitle: "RUDN University",
        url: "https://eng.rudn.ru/"
    },
    {
        key: "p8",
        img: p8,
        title: "АНО «Содружество спортивной дипломатии»",
        enTitle: "The Commonwealth of Sports Diplomacy",
        url: "https://vk.com/sportdilomacy"
    },
    {
        key: "p9",
        img: p9,
        title: "Региональное отделение Русско-Азиатского Союза промышленников и предпринимателей (РАСПП, г. Ульяновск)",
        enTitle: "The Russian-Asian Union of Industrialists and Entrepreneurs",
        url: "https://raspp.ru/"
    },
    {
        key: "p10",
        img: p10,
        title: "ABFB Aliança Bayeux Franco Brasileira (Бразилия)",
        enTitle: "ABFB (Aliança Bayeux Franco Brasielira)",
        url: "https://aliancabayeux.org.br/"
    },
    {
        key: "p11",
        img: p11,
        title: "Клуб молодежи мира (г. Казань)",
        enTitle: "Youth of the World Club",
        url: "https://clubmolodyozhmira.tilda.ws/"
    },
    {
        key: "p12",
        img: p12,
        title: "Smart Mir Services",
        enTitle: "Smart Mir Services",
        url: "https://www.smartmirservices.com/"
    },
    {
        key: "p13",
        img: p13,
        title: "ФГБОУ ВО «Херсонский государственный педагогический университет» ",
        enTitle: "Kherson State Pedagogical University",
        url: "https://hgpurf.ru/"
    },
    {
        key: "p14",
        img: p14,
        title: "Комитет здравоохранения БРИКС",
        enTitle: "BRICS Committee for International Cooperation in Health and Healthcare",
        url: "https://bricshealthcare.com/"
    },
    {
        key: "p15",
        img: p15,
        title: "Ассоциация молодежных обменов Китая и России при Союзе китайских учащихся в России ",
        enTitle: "The Association of Youth Exchanges of China and Russia under the Union of Chinese Students in Russia",
        url: "https://vk.com/club163002051"
    },
    {
        key: "p16",
        img: p16,
        title: "Приключения иностранцев в России",
        enTitle: "Adventures of foreigners in Russia",
        url: "https://t.me/inostranets_v_RF"
    },
]